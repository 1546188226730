import React from 'react'
import { useInView } from 'react-intersection-observer';

function TraiteurTitle(props) {

    const { ref, inView} = useInView({
        threshold: 0.2,
        trackVisibility: true,
        skip: !props.loaded,
        triggerOnce: true,
        delay: 100,
      });

    return (
        <div ref={ref} className={`product-item-title d-flex transparent ${inView ? 'slide-in-right': ''}`}>
            <div className={`bg-faded p-5 d-none d-flex ml-auto rounded shadow1`}>
                <h2 className="section-heading mb-0 section-heading-lowe text-center">
                    {props.children}
                </h2>
            </div>
        </div>
    )
}

export default TraiteurTitle
