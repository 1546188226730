import React from 'react'
import { useInView } from 'react-intersection-observer';

function SlideLeftOnAppear(props) {

    const { ref, inView} = useInView({
        threshold: 0.2,
        trackVisibility: true,
        skip: !props.loaded,
        triggerOnce: true,
        delay: 100,
      });


    return (
        <div ref={ref} className={`transparent ${inView ? 'slide-in-left': ''}`}>
            {props.children}
        </div>
    )
}

export default SlideLeftOnAppear
