import React, {useState,useEffect,useCallback} from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import TraiteurTitle from '../components/TraiteurTitle'
import OnImagesLoaded from 'react-on-images-loaded';
import SlideLeftOnAppear from "../components/SlideLeftOnAppear"
import FadeIn from "../components/FadeIn"

import product1 from '../assets/images/resto-roul.jpg';
import product2 from '../assets/images/salle-traiteur-mariage.jpg';
import evenementRelaisDeLavance from '../assets/images/evenement-relais-de-lavance.jpg';
import mariage from '../assets/images/mariage.jpg';
import qualite from '../assets/images/qualitee-traiteur.jpg'
import buffetTraiteurMariage from '../assets/images/buffet-traiteur-mariage.jpg'
import traiteurConvivialite from '../assets/images/traiteur-convivialite.jpg'


const IndexPage = () => {

  const [loaded, setloaded] = useState(false)


  useEffect(() => {
    if(!loaded && typeof window !== undefined)
    {
      setloaded(true)
    }
    return () => {
      
    }
  }, [])

  return (
    <>
      <Helmet
            title="Traiteur pour mariage Hautes Alpes avec le resto'roul - Le relais de l'avance"
            meta={[
              { name: 'description', content: "Traiteur pour mariage situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05. Grace au concept de resto'roul avec notre caravane transformé en cuisine, nous cuisinons directement sur le lieu de vos événements." },
              { name: 'og:title', content: "Relais de l'avance Restaurant traiteur mariage Hautes Alpes 05"},
              { name: 'og:description', content: "Le relais de l'avance est un Bar - restaurant - traiteur pour mariage avec le concept resto'roul situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05"},
              { name: 'og:type', content: "website"}
            ]}
          >
        </Helmet>
      <Layout activeLink="traiteur">
        <section className="page-section">
          <div className="container">
            <div className="product-item">
              <div className="product-item-title d-flex">
                <div className="bg-faded p-5 d-flex ml-auto rounded shadow1">
                  <h1 className="section-heading mb-0 section-heading-lower">
                      Traiteur pour mariage des Hautes Alpes et tout évènements, avec notre resto'roul
                  </h1>
                </div>
              </div>
                <FadeIn loaded={loaded}>
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0 shadow2"
                    src={product1}
                    alt="Traiteur pour mariage hautes alpes avec le resto'roul"
                  />
                </FadeIn>
              {/* <div className="product-item-description d-flex mr-auto">
                <div className="bg-faded p-5 rounded">
                  <p className="mb-0">
                    We take pride in our work, and it shows. Every time you order a
                    beverage from us, we guarantee that it will be an experience
                    worth having. Whether it's our world famous Venezuelan
                    Cappuccino, a refreshing iced herbal tea, or something as simple
                    as a cup of speciality sourced black coffee, you will be coming
                    back for more.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="page-section">
          <div className="container">
            <div className="product-item">
                <TraiteurTitle loaded={loaded}>
                  LE RESTO’ROUL UN TRAITEUR HAUT ALPIN POUR DES EVENEMENTS RÉUSSIS
                </TraiteurTitle>
                  <FadeIn loaded={loaded}>
                    <img
                      className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0 shadow2"
                      src={product2}
                      alt="salle réception mariage"
                    />
                  </FadeIn>
              <SlideLeftOnAppear loaded={loaded}>
                <div className="product-item-description d-flex ml-auto">
                  <div className={`bg-faded p-5 rounded shadow1`}>
                    <article className="mb-0">
                      <p>Situé à Saint-Étienne-le-Laus dans le département des hautes alpes 05, <br/>
                      nous avons créé ce concept particulier de <strong>traiteur</strong> en 2011 et qui se situe entre Food truck, <strong>restaurant à domicile</strong>.</p>
                      <p>Ce concept est une caravane transformée en <strong>cuisine</strong> qui nous permet de cuisiner sur place et dans des lieux uniques tout en étant au plus proche des <strong>convives</strong>.</p>
                      <p>Nous nous déplaçons directement sur le lieu de <strong>réception</strong>, vous proposant <strong>apéritif</strong>, assortiments de <strong>petits-fours</strong>, <strong>apéro dinatoire</strong>, <strong>buffet gastronomique</strong>, <strong>buffet froid</strong>, sans oublier les gourmandises.</p> 
                      <p>Ce service <strong>traiteur</strong> à domicile qui se déplace avec sa cuisine nous permet de mener au mieux notre métier de restaurateur et nous tenons à cette appellation <strong>“Resto’roul”</strong> qui est notre force et notre différence.</p>
                    </article>
                  </div>
                </div>
              </SlideLeftOnAppear>
            </div>
          </div>
        </section>

        <section className="page-section" >
          <div className="container">
            <div className="product-item">
                <TraiteurTitle loaded={loaded}>
                    Un traiteur pour tous vos évènements : mariages, Réceptions privées ...
                </TraiteurTitle>
                  <FadeIn loaded={loaded}></FadeIn>
                    <img
                      className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0 shadow2"
                      src={evenementRelaisDeLavance}
                      alt="Traiteur pour mariage département hautes alpes 05"
                    />
                  <FadeIn loaded={loaded}></FadeIn>
                <SlideLeftOnAppear loaded={loaded}>
                  <div className="product-item-description d-flex mr-auto ">
                    <div className={`bg-faded p-5 rounded shadow1 opacity-5`}>
                      <p className="mb-0">
                        Vous avez un événement à célébrer ? nous intervenons dans l'organisation de réception de tous type :
                      </p>
                      <br />
                      <ul>
                        <li>Réceptions privées</li>
                        <li>Rassemblement familiaux</li>
                        <li>Repas d'entreprise</li>
                        <li>Repas de famille</li>
                        <li>Événements professionnels</li>
                        <li>Anniversaires</li>
                        <li>Mariage</li>
                        <li>Séminaires</li>
                        <li>Départ à la retraite</li>
                        <li>Communion</li>
                        <li>Baptême</li>
                      </ul>
                      <p>
                      <Link to="/contact-le-relais-de-lavance">Contactez nous</Link> pour nous faire part de votre projet d'évènement
                      </p>
                    </div>
                  </div>
              </SlideLeftOnAppear>
            </div>
          </div>
        </section>

        <section className="page-section">
          <div className="container">
            <div className="product-item">
              <TraiteurTitle loaded={loaded}>
                Un traiteur pour mariage synonyme de receptions inoubliables. faites confiance en notre savoir faire pour le repas de votre union
              </TraiteurTitle>
                <FadeIn loaded={loaded}>
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0 shadow2"
                    src={mariage}
                    alt="traiteur pour mariage dans les hautes alpes"
                  />
                </FadeIn>
              <SlideLeftOnAppear loaded={loaded}>
                <div className="product-item-description d-flex mr-auto">
                  <div className={`bg-faded p-5 rounded shadow1 opacity-5`}>
                    <article className="mb-0">
                      <h3>Un traiteur pour mariage pour des prestations sur-mesure</h3><br />
                      <p>Tout d'abord permetez nous de vous féliciter pour ce très bel évènement en prévision qui marque une vie.</p>
                      <p>En tant que <strong>traiteur</strong> événementiel,<br/>
                      S'il y a bien un événement exceptionnel pour lequel nous aimons cuisiner c’est bien celui du <strong>mariage</strong>.</p> 
                      <p>Nous sommes particulièrement reconnus dans le domaine des réceptions de <strong>mariage</strong>, en proposant un <strong>buffet</strong> <strong>convivial</strong> mais <strong>gourmet</strong> pour régaler les mariés et les convives pour ce moment inoubliable si <strong>exceptionnel</strong>.</p>
                      <p>Si vous avez envie d'une <strong>réception</strong> colorée, <strong>savoureuse</strong> et originale, c'est possible avec <strong>Le relais de l'avance</strong>. Cette équipe de professionnels expérimentés met tout en œuvre pour réaliser le menu de vos rêves et vous conseiller au mieux durant les préparatifs.</p>
                      <p>Après tout, votre mariage n'est-il pas la meilleure des occasions pour savourer avec vos invités un repas gourmand et délicieux qui leur laissera de superbes souvenirs du jour de votre union ?</p>
                      <h3>Laissez-nous-vous aider durant les préparatifs du mariage</h3> <br/>
                      <p>On sait tous qu'en tant qu'organisateur, les préparatifs d'un mariage sont toujours épuisants, il faut penser à tout ! La liste des invités, le plan de table, le lieu de réception, la location de la salle et beaucoup d'autre chose...<br/> Laissez donc notre équipe de traiteurs vous soulager de quelques taches : Les préparatifs du menu et du repas.<br /> Faites-nous part de vos désirs culinaires pour votre <strong>réception</strong>, du nombre d'invités, vos préférences, nous nous adaptons à vous !<br/>
                      Peut-être avez-vous envis de cocktails, foie-gras, saumon fumé, magret de canard, paelas, fromage ? Faites confiance à notre chef traiteur et à son expérience de 11 ans en tant que <strong>traiteur pour mariage</strong> vous ne serez pas déçus.</p>
                      <p>Pour répondre au mieux aux souhaits de nos clients nous faisons toujours en sorte d'adapter nos repas de mariage à vos critères, vos goûts en s’adaptant à votre budget.</p>
                      <p>Nous vous proposons également des dégustations de nos préparations : verrines, assortiment de petits-fours, amuse-bouche, pour vous aider à sélectionner au mieux les délices de votre futur buffet.</p>
                      <p>Grâce à notre concept de <strong>traiteur</strong> mobile <strong>“resto’roul”</strong>, nous pouvons cuisiner directement sur le lieu de réception pour vous proposer les plats les plus frais et gourmets possibles pour votre <strong>mariage</strong>.</p>
                      <p><Link to="/contact-le-relais-de-lavance">Fait nous part de notre projet de mariage</Link> et nous reviendrons rapidement vers vous.</p>
                    </article>
                  </div>
                </div>
              </SlideLeftOnAppear>
            </div>
          </div>
        </section>

        <section className="page-section">
          <div className="container">
            <div className="product-item">
              <TraiteurTitle loaded={loaded}>
                  Un service traiteur engagé sur la qualité
              </TraiteurTitle>
                <FadeIn loaded={loaded}>
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0 shadow2"
                    src={qualite}
                    alt=""
                  />
                </FadeIn>
              <SlideLeftOnAppear loaded={loaded}>
                <div className="product-item-description d-flex mr-auto">
                  <div className="bg-faded p-5 rounded shadow1">
                    <article className="mb-0">
                      <p>C’est donc un chef <strong>cuisinier</strong> et sa cuisine qui se déplace sur vos <strong>évènements</strong> privés.</p>
                      <p>C’est sans conteste le plus de ce concept car outre le fait de pouvoir <strong>cuisiner</strong> sur vos lieux de <strong>réception</strong>,</p>
                      <p>nous gardons à chaque instant la maîtrise des cuissons, de la chaîne de froid, de l’environnement, un matériel important toujours à porté de main, c’est notre engagement <strong>qualité</strong>.</p>
                    </article>
                  </div>
                </div>
              </SlideLeftOnAppear>
            </div>
          </div>
        </section>

        <section className="page-section">
          <div className="container">
            <div className="product-item">
              <TraiteurTitle loaded={loaded}>
                  Nos buffets traiteur s'adaptent à vous pour vous proposer le menu idéale pour votre réception
              </TraiteurTitle>
                <FadeIn loaded={loaded}>
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0 shadow2"
                    src={buffetTraiteurMariage}
                    alt="Buffet-traiteur-mariage"
                  />
                </FadeIn>
              <SlideLeftOnAppear loaded={loaded}>
                <div className="product-item-description d-flex mr-auto">
                  <div className="bg-faded p-5 rounded shadow1">
                    <article className="mb-0">
                      <p>La base de nos <strong>buffets</strong> se déclinent en quatre parties pour les entrées : les salades, la charcuterie, le poisson et les spécialités.</p>
                      <p>De même, pour le plat principal, nous vous offrons la possibilité de proposer à vos invités deux choix de plats</p>
                      <p>Toutes nos propositions se font en fonction de vos thèmes, de vos goûts et de votre budget.</p>
                      <p>Nous avons pour principe la volonté de nous mettre à la portée de tout le monde en restant au prix le plus juste.</p>
                      <p>C’est pourquoi nous n’avons pas vraiment de menu fixe à proposer mais plutôt un ensemble d’idée qui nous permettra ensuite de composer le menu idéal en terme de goût, de thème, de régime, tout en s’adaptant à votre budget.</p>
                    </article>
                  </div>
                </div>
              </SlideLeftOnAppear>
            </div>
          </div>
        </section>

        <section className="page-section">
          <div className="container">
            <div className="product-item">
              <TraiteurTitle loaded={loaded}>
                    Le traiteur des hautes alpes basé sur la convivialité
              </TraiteurTitle>
                <FadeIn loaded={loaded}>
                  <img
                    className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0 shadow2"
                    src={traiteurConvivialite}
                    alt="Buffet-traiteur-mariage"
                  />
                </FadeIn>
              <SlideLeftOnAppear loaded={loaded}>
                <div className="product-item-description d-flex mr-auto shadow1">
                  <div className="bg-faded p-5 rounded">
                    <article className="mb-0">
                      <p>Nous avons un style personnel basé sur la <strong>convivialité</strong> et la simplicité, c’est également notre marque de fabrique.</p> 
                      <p>Une simplicité qui n'empêche en rien le <strong>raffinement</strong> <strong>culinaire</strong> de nos <strong>savoureux</strong> plats à base de produits de <strong>qualité</strong>, pour un moment inoubliable.</p>
                      <p>Nous fournissons la location de vaisselles et tout le matériel nécessaire à nos repas quasi gracieusement.</p>
                      <p>Conservez votre <strong>sérénité</strong> et dites nous en plus sur votre projet <strong>événementiel</strong> en nous <Link to="/contact-le-relais-de-lavance">contactant</Link>.</p>
                    </article>
                  </div>
                </div>
              </SlideLeftOnAppear>
            </div>
          </div>
        </section>
      </Layout>
  </>
)};

export default IndexPage;
